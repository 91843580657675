<template>
	<section>
		<div class="banner"><div class="banner-con">Gorex · 收银台</div></div>
		<template v-if="pay_status">
			<div class="order-con pay">
				<div class="order-title">欢迎订购聚码产品</div>
				<div class="pay-con">
					<div>{{paydata.name}}</div>
					<div class="price">￥ <span>{{paydata.payment}}</span></div>
					<div class="pay_success">
						<i class="el-icon-circle-check"></i>
						<div class="pay_status">支付成功</div>
						<p class="psu_intro">感谢您对聚码的支持，我们的服务人员将与你取得联系，并提供软件使用指导。</p>
					    <!-- <div class="refresh">*点击付款二维码刷新</div> -->
					</div>
					<div class="use" @click="go_product">查看应用</div>
				</div>
				
			</div>
		</template>
		<template v-else>
			<div class="order-con" v-if="orderType">
				<div class="order-title">欢迎订购聚码产品，请确认以下信息：</div>
				<div class="order-title con-title">请选择版本：</div>

				<div class="list-con">
					<!-- <div class="list-label">新购</div> -->
					<div class="list">

						<template v-for="(val,index) in detailD">
							<div class="list-item" :class="{active: val.id==order.editionid}" :key="index" @click="handleChoose(val.id,val.sale)">
								<div class="list-item-left">
									<!-- <span class="list-label" v-if="val.id==order.editionid">新购</span> -->
									<div class="order-title">
										{{val.cloud}} · {{val.edition}}</div>
									<div class="l-i-text">{{val.intro}}</div>
								</div>
								
								<div class="l-i-con">
									
									<div class="l-i-price">￥ <span>{{val.price}}</span></div>
								</div>
							</div>
						</template>
						


					</div>

				</div>
				<div style="font-size: 12px;color: red;margin-top: 4px;text-align: right;">*本产品支持30天内无理由全额退款</div>

				<div class="order-title con-title" style="margin-top: 30px;">请选择时长：</div>
				<div class="time">
					<el-radio-group v-model="code" size="medium" @change="handlechange">
						<template v-for="(val,index) in saleD">
							<el-radio-button :key="index" :label="val.durationid">{{val.name}} <span class="discount">{{val.sale}}</span></el-radio-button>
						</template>
				    </el-radio-group>
				</div>
				<div class="order-details">
					<div class="rec-code" @click="usecode">使用推荐码</div>
					<div class="detail-item">
						<div class="d-i-l">原价</div>
						<div class="d-i-r">{{pagedata.price}}</div>
					</div>
					<div class="detail-item">
						<div class="d-i-l">活动折扣</div>
						<div class="d-i-r">- {{pagedata.sale}}</div>
					</div>
					<div class="detail-item">
						<div class="d-i-l">推荐码折扣</div>
						<div class="d-i-r">- {{pagedata.coupon}}</div>
					</div>
					<div class="detail-item">
						<div class="d-i-l">您需支付</div>
						<div class="d-i-r active">{{pagedata.payment}}</div>
					</div>
					<div class="login-code">*已认真阅读<a href="">《使用条款》</a>与<a href="">《隐私政策》</a></div>	
	                <el-button type="primary" @click="handleSubmit">确认购买</el-button>
				</div>

			</div>

			<div class="order-con pay" v-else>
				<div class="order-title">欢迎订购聚码产品，请打开手机支付宝扫码支付：</div>
				<div class="pay-con">
					<div style="font-weight: bolder;">{{paydata.name}}</div>
					<div class="price">￥ <span>{{paydata.payment}}</span></div>
					<div class="list-img">
						<el-image fit="contain" :src="paydata.alipay">
					      <div slot="error" class="image-slot">
					        <i class="el-icon-picture-outline"></i>
					      </div>
					    </el-image>
					    <div class="scan">
					    	<i class="iconfont icon-saoyisao"></i>
					    	<div>
					    		<p>打开手机支付宝</p>
					    		<p>扫一扫继续支付</p>
					    	</div>
					    </div>
					    <!-- <div class="refresh">*点击付款二维码刷新</div> -->
					</div>
				</div>
			</div>
		</template>
	</section>
</template>
<script type="text/javascript">
	export default {
    data(){
        return {
        	code:'40',
        	cloudid: '',
        	detailD:[],
        	saleD: [],
        	order:{
        		editionid: '',
        		durationid:''
        	},
        	pagedata:{},
        	coupon: '',
        	orderType: true,//显示二维码
        	pay_status: false,//支付是否成功
        	paydata:{},
        	orderid: '',
        	timer: '',
        	editionid: ''
        }
    },
	mounted(){
		this.cloudid = this.$route.query.id;
		this.editionid = this.$route.query.editionid;
		this.pageinit();
	},
	beforeRouteLeave (to, from, next) {
      // 即将跳转的路由地址

      clearInterval(this.timer);
      next();
  	},
	methods: {
		handleSubmit(){
			let _this = this;
			var obj = this.order;
			obj.coupon = this.coupon;
			this.axios.post('/cloud/order/confirm', {
                order: obj
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                	_this.orderid = res.extra.orderid;
                    _this.paymentF();

                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		paymentF(){
			let _this = this;
			this.axios.post('/cloud/order/payment', {
                orderid: _this.orderid
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                	_this.paydata = res.extra;
                    _this.$nextTick(function () {
                        _this.orderType = false;
                        _this.timer = setInterval(_this.refreshF, 2000);
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		refreshF(){
			let _this = this;
			this.axios.post('/cloud/order/complete', {
                paymentno: this.paydata.paymentno
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    clearInterval(_this.timer);
                    _this.pay_status = true;
                   

                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		go_product(){
			var host = window.location.host;
            var url = 'https://'+host+'/v/gorex/client/product/myapp/myapp_list';
            window.location.replace(url);
        },
		handleChoose(id,sale){
			this.order.editionid = id;
			this.saleD = sale;
			this.getprice();
		},
		handlechange(val){
			this.order.durationid = val;
			this.getprice();
		},
		getprice(){
			let _this = this;
			this.axios.post('/cloud/nologin/pre_price', {
                order: this.order
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                        _this.pagedata = res.extra;
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		pageinit(){
			let _this = this;
			this.axios.post('/cloud/nologin/pre_order', {
                cloudid: this.cloudid
            })
            .then(function (msg) {
                const res = msg.data;
                if (!res.err) {
                    _this.$nextTick(function () {
                        _this.detailD = res.extra.edition;
                        _this.saleD = res.extra.edition[0].sale;
                        _this.order = {
                        	editionid: res.extra.edition[0].id,
                        	durationid: '40'
                        }
                        if(_this.editionid){
                        	_this.order.editionid = _this.editionid;
                        }
                        _this.getprice();
                    })
                    
                } else {
                    _this.logining = false;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
		},
		usecode(){
			this.$prompt('请输入推荐码', '提示', {
	          confirmButtonText: '确定',
	          cancelButtonText: '取消',
	        }).then(({ value }) => {
	          this.coupon = value;
	        }).catch(() => {
	          this.$message({
	            type: 'info',
	            message: '取消输入'
	          });       
	        });
		}
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	

	.banner{
		width: 100%;
		height: 233px;
		background-color: #F2F2F2;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.banner-con{
		width: 1000px;
		height: 100%;
		display: flex;
		align-items: center;
		font-size: 24px;
		font-weight: bold;
		color: #333333;
	}
	.order-con{
		width: 822px;
		background-color: #fff;
		border: solid 1px #E5E5E5;
		border-radius: 10px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 80px;
		padding: 40px 40px;
		color: #333;
	}
	.order-title{
		font-size: 16px;
		/*font-weight: bold;*/
		
	}
	.con-title{
		font-size: 14px;
		margin-top: 80px;
		font-weight: bolder;
	}
	.list-con{
		width: 100%;
		border: solid 1px #E5E5E5;
		border-radius: 5px;
		/*padding: 24px;*/
		display: flex;
		margin-top: 6px;
	}
	.list-label{
		flex-shrink: 0;
		font-size: 10px;
		color: #169BD5;
		margin-right: 12px
	}
	.list{
		flex: 1;
	}
	.list-item{
		padding: 24px 40px;
		width: 100%;
		border-bottom: solid 1px #E5E5E5;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.list-item.active{
		background-color: rgba(0,112,204,.15);
	}
	.list-item:last-child{
		border: none;
		/*padding-bottom: 0;*/
	}
	.list-item-left{
		flex:1;
	}
	.l-i-con{
		flex-shrink: 0;
		display: flex;
		margin-left: 40px;
	}
	.l-i-text{
		font-size: 12px;
		color: #848282;
		margin-top: 6px;
	}
	.l-i-price{
		font-size: 16px;
		color: #333333;
	}
	.l-i-price span{
		font-weight: bolder;
	}

	.time{
		width: 100%;
		border-radius: 5px;
		display: flex;
		align-items: center;
		padding: 6px 0;
		margin-bottom: 6px;
	}
	.discount{
		font-size: 12px;
		color: #169BD5;
		font-weight: 300;
		transform: scale(0.8);
	}
	/deep/.el-radio-button .el-radio-button__inner{
		min-width: 88px;
		font-weight: 400;
		/*margin: 5px 0;*/
		color: #373d41;
		box-shadow: none;
	}
	/deep/.el-radio-button__inner:hover{
		border-color: #b2d4ef;
	}
	/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
		border-color: #b2d4ef;
	    background: rgba(0,112,204,.15);

	}
	/deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner .discount{
		color: #373d41;
	}


	.order-details{
		margin-left: 60%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		margin-top: 100px;
	}
	.rec-code{
		font-size: 12px;
		line-height: 12px;
		color: #169BD5;
		margin-bottom: 16px;
		margin-left: auto;
		cursor: pointer;
	}
	.rec-code:hover{
		color: #E79E0D;
	}
	.detail-item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 12px;
		color: #333333;
		margin-left: 68px;
	}
	.d-i-l{
		font-size: 14px;
		font-weight: bold;
	}
	.d-i-r{
		flex-shrink: 0;
		font-size: 16px;
		margin-left: 12px;
	}
	.d-i-r.active{
		font-weight: bold;
	}
	.login-code{
		display: flex;
		align-items: center;
		justify-content: flex-end;
		font-size: 12px;
		color: #333;
		margin-top: 40px;
	}
	.login-code a{
		color: #169BD5;
	}
	/deep/.el-button--primary{
		width: 190px;
		height: 36px;
		background-color: #169BD5;
		border-radius: 5px;
		font-size: 13px;
		color: #fff;
		border-color: #169BD5;
		margin-left: auto;
		margin-top: 6px;
	}
	
	.pay{
		height: 700px;
	}
	.pay-con{
		margin: 60px 0 180px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 14px;
		color: #333333;
	}
	.price{
		font-size: 24px;
		font-weight: bolder;
		margin-top: 4px;
		color: #df6919;
	}
	.price span{
		font-weight: bolder;
	}
	.list-img{
		width: 280px;
		margin-top: 50px;
		padding: 4px 4px 18px 4px;
		border: 1px solid #B5B3B3;
		overflow: hidden;
		
	}
	.pay_success{
		width: 280px;
		margin-top: 80px;
		display: flex;
		align-items: center;
		/*justify-content: center;*/
		flex-direction: column;
	}
	.el-image{
		width: 100%;
		height: 280px;
		display: flex;
		align-items: center;
		justify-content: center;
		
	}
	.pay_success i{
		font-size: 80px;
		color: green;
	}
	.pay_status{
		margin-top: 26px;
		font-size: 22px;
		color: #333;

		/*color: green;*/
	}
	.psu_intro{
		font-size: 14px;
		color: #999;
		margin-top: 10px;
		text-align: center;
	}
	.refresh{
		margin-top: 10px;
		text-align: right;
		font-size: 12px;
		color: #909399;
	}
	.use{
    	width: 153px;
    	height: 35px;
    	border-radius: 5px;
    	border: 1px solid #999;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	font-size: 14px;
    	color: #999;
    	cursor: pointer;
    	margin-top: 60px;
    }
    .use:hover{
		color: #E79E0D;
		border: 1px solid #E79E0D;
    }
    .scan{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	font-size: 14px;
    	color: #333;
    	/*margin-top: 10px;*/
    }
    .scan i{
    	font-size: 40px;
    	color: #e7ad42;
    	margin-right: 8px;
    }
</style>